import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState, useRef } from 'react';
import { get, post } from './api';
import './App.css';
import sound1 from './sounds/horn.mp3';
import sound2 from './sounds/warn.wav';
import sound3 from './sounds/success.wav';
import logo_circle_dark from './images/logo_circle_dark.png';
// const electron = window.require("electron")
// const { ipcRenderer } = electron;
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function App() {
  const [formData, setFormData] = useState({
    warehouse_id: 1,
    w_id_1: 93,
    w_id_2: 94,
    w_id_3: 95,
    w_id_4: 96,
    c_id_1: "",
    c_id_2: "",
    c_id_3: "",
    c_id_4: "",
    awb_no: '',
    barcode: '',
    send_notifications: false,
    set_awb: false,
    abort_unpaids: false,
    working_on_courier: false,
  });
  const [lastData, setLastData] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [courier_warehouses, setCourierWarehouses] = useState([]);
  const [message, setMessage] = useState({
    type: 'primary',
    text: 'Scanner istifadeye hazirdir.'
  })
  const [isLoading, setLoading] = useState(false);
  const inputEl = useRef(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginFormData, setLoginFormData] = useState({ email_address: '', password: '' });
  const [token, setToken] = useState(null)
  useEffect(() => {
    const tok = localStorage.getItem('@token')
    setToken(tok)
    if (!tok) {
      setShowLoginModal(true);
    }
  }, []);

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLoginSubmit = async () => {
    try {
      const { access_token } = await post('/admin/auth/signin', loginFormData)
      localStorage.setItem('@token', access_token)
      setToken(access_token)
      setShowLoginModal(false);
    } catch (error) {
      window.alert(error.message)
    }

  };
  const getWarehouses = async () => {
    setLoading(true)
    try {
      const courier_warehouses = await get('/warehouses', "?courier=1");
      const warehouses = await get('/warehouses');
      setWarehouses(warehouses)
      setCourierWarehouses(courier_warehouses)
    } catch (error) {
      setMessage({ type: 'danger', message: 'Warehouses not found!' });
    }
    setLoading(false)
  }


  const onChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  }

  useEffect(() => {
    setLoading(true)
    getWarehouses()
    setLoading(false)
  }, [])

  useEffect(() => {
    if (formData?.barcode?.length === 13) {
      if (!token) {
        inputEl.current.value = '';
        setFormData(prevState => ({ ...prevState, barcode: '' }));
        return setShowLoginModal(true)
      }

      (async () => {
        setLoading(true)
        try {
          const { type, text, packages, print_data, full_name, weight, auto_print, label_url } = await post('/v3/bag', formData);
          if (print_data && auto_print)
            {
              window.open(label_url, '_blank')
              
            }
          setMessage({ type, text });
          setLastData(prev => ({ packages, print_data, full_name, weight, label_url}));
        } catch (error) {
          setMessage({ type: 'danger', text: 'Xeta bash verdi!' });
        }
        setLoading(false)
      })()
      inputEl.current.value = '';
      setFormData(prevState => ({ ...prevState, barcode: '' }));
    }
  }, [formData, token])

  useEffect(() => {
    if (message.type === 'danger') {
      const audio = new Audio(sound1);
      audio.play()
    }
    if (message.type === 'info') {
      const audio = new Audio(sound2);
      audio.play()
    }
    if (message.type === 'success') {
      const audio = new Audio(sound3);
      audio.play()
    }
  }, [message])



  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-9 logo-col mr-3">
          <img src={logo_circle_dark} alt="logo_circle_dark" />

        </div>
        <div className='col-3'>
          <button onClick={
            token 
                ? () => {
                  setToken(null)
                  localStorage.clear()
                }
                : () => setShowLoginModal(true)
          }>{!token ? 'Daxil ol' : 'Cixis'}</button>
        </div>
      </div>

      <div className="row mt-3">

        <div class="row m-1">
          <div className="col-6">
            <select
              className="form-control  form-control-lg"
              name="w_id_1"
              readOnly={isLoading}
              value={formData.w_id_1}
              onChange={onChange}
            >
              {courier_warehouses.map(w => <option key={w.id} value={w.id}>WID{w.id} - {w.description}</option>)}
            </select>
          </div>

          <div className="col-6">
            <input
              className="form-control form-control-lg"
              name="c_id_1"
              placeholder="Courier id"
              readOnly={isLoading}
              value={formData.c_id_1}
              onChange={onChange}
            />
          </div>
        </div>

        <div class="row m-1">
          <div className="col-6">
            <select
              className="form-control  form-control-lg"
              name="w_id_2"
              readOnly={isLoading}
              value={formData.w_id_2}
              onChange={onChange}
            >
              {courier_warehouses.map(w => <option key={w.id} value={w.id}>WID{w.id} - {w.description}</option>)}
            </select>
          </div>

          <div className="col-6">
            <input
              className="form-control form-control-lg"
              name="c_id_2"
              placeholder="Courier id"
              readOnly={isLoading}
              value={formData.c_id_2}
              onChange={onChange}
            />
          </div>
        </div>

        <div class="row m-1">
          <div className="col-6">
            <select
              className="form-control  form-control-lg"
              name="w_id_3"
              readOnly={isLoading}
              value={formData.w_id_3}
              onChange={onChange}
            >
              {courier_warehouses.map(w => <option key={w.id} value={w.id}>WID{w.id} - {w.description}</option>)}
            </select>
          </div>

          <div className="col-6">
            <input
              className="form-control form-control-lg"
              name="c_id_3"
              placeholder="Courier id"
              readOnly={isLoading}
              value={formData.c_id_3}
              onChange={onChange}
            />
          </div>
        </div>

        <div class="row m-1">
          <div className="col-6">
            <select
              className="form-control  form-control-lg"
              name="w_id_4"
              readOnly={isLoading}
              value={formData.w_id_4}
              onChange={onChange}
            >
              {courier_warehouses.map(w => <option key={w.id} value={w.id}>WID{w.id} - {w.description}</option>)}
            </select>
          </div>

          <div className="col-6">
            <input
              className="form-control form-control-lg"
              name="c_id_4"
              placeholder="Courier id"
              readOnly={isLoading}
              value={formData.c_id_4}
              onChange={onChange}
            />
          </div>
        </div>

        <div class="row m-3">
          <div class="col-12">
            <hr></hr>
            <span> For courier</span>
          </div>

        </div>


        <div className="row m-2">
          <div className="col-6">
            <select
              className="form-control  form-control-lg"
              name="warehouse_id"
              readOnly={isLoading}
              value={formData.warehouse_id}
              onChange={onChange}
            >
              {warehouses.map(w => <option key={w.id} value={w.id}>WID{w.id} - {w.description}</option>)}
            </select>
          </div>
          <div className="col-6">
            <input
              className="form-control form-control-lg"
              name="awb_no"
              placeholder="AWB NO"
              readOnly={isLoading}
              value={formData.awb_no}
              onChange={onChange}
            />
          </div>

        </div>


        <div className="col-3">
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="send_notifications">Bildiriş göndər</label>
                <input type="checkbox" id="send_notifications"
                  checked={formData.send_notifications}
                  onChange={e => setFormData({ ...formData, send_notifications: e.target.checked })}
                  className="ml-2" name="send_notifications" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="working_on_courier"><strong>Kuryer</strong></label>
                <input type="checkbox" id="working_on_courier"
                  checked={formData.working_on_courier}
                  onChange={e => setFormData({ ...formData, working_on_courier: e.target.checked })}
                  className="ml-2" name="working_on_courier" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="set_awb">Set AWB</label>
                <input type="checkbox" id="set_awb"
                  checked={formData.set_awb}
                  onChange={e => setFormData({ ...formData, set_awb: e.target.checked })}
                  className="ml-2" name="set_awb" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="abort_unpaids">Ancaq ödənilmiş</label>
            <input type="checkbox" id="abort_unpaids"
              checked={formData.abort_unpaids}
              onChange={e => setFormData({ ...formData, abort_unpaids: e.target.checked })}
              className="ml-2" name="abort_unpaids" />
          </div>
        </div>
        <div className="col-2">
          {lastData?.label_url&& <button className="btn btn-primary btn-lg" onClick={() => window.open(lastData.label_url, '_blank')}>
            Cap et
          </button>}
          {/* <button className="btn btn-secondary btn-lg"
            disabled={formData.awb_no === ''}
            onClick={printOneLefts}>
            1 Qalanlar
          </button> */}
        </div>
      </div>
      <div className="mt-3" />

      <div className="row bg-dark py-3">
        <div className="col-3">
          <input
            className="form-control form-control-lg"
            type="text"
            name="barcode"
            readOnly={isLoading}
            placeholder="0000000000000"
            onChange={onChange}
            ref={inputEl}
          />
        </div>
        <div className="col-9">
          <div className={`alert alert-${message.type}`}>
            {message.text}
          </div>
        </div>
      </div>
      {lastData?.packages?.length > 0 && <>
        <div className="mt-5" />
        <div className="row">
          <div className="col-12">
            <h4>Istifadeci: {lastData?.full_name}</h4>
            <h5>Total ceki: {lastData?.weight?.toFixed(2)} KG</h5>
          </div>
          {lastData.packages.map(b => <div className="col-2" key={b}>
            <div className="badge badge-lg bg-dark text-white">
              {b}
            </div>
          </div>)}
        </div>
      </>}

      <div className="fixed-bottom mx-2">
        <div className="row p-1 bg-dark text-white">
          <div className="col-3">
            Status: {isLoading ? 'Yuklenilir...' : 'Hazir'}
          </div>
          <div className="col-9 text-right">
            v1.0.0
          </div>
        </div>
      </div>
      {/* Login Modal */}
      <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Email_address address</label>
              <input
                type="email_address"
                className="form-control"
                name="email_address"
                value={loginFormData.email_address}
                onChange={handleLoginChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={loginFormData.password}
                onChange={handleLoginChange}
                required
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLoginModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLoginSubmit}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
