const HEADERS = { 'Content-Type': 'application/json', 'lang': 'tr', 'Accept': 'application/vnd.api+json' };
const API_URL = 'https://api.expargo.com'
// const API_URL = 'http://localhost:5001'


export const post = async (url, data) => {
    const token = localStorage.getItem('@token')
    return fetch(`${API_URL}${url}`,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {...HEADERS, Authorization: `Bearer ${token}`}
        })
        .then(handleResponse)
        .catch(handleError)
}

export const put = async (url, data) => {
    return fetch(`${API_URL}${url}`,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: HEADERS
        })
        .then(handleResponse)
        .catch(handleError)
}

export const del = async (url, data) => {
    return fetch(`${API_URL}${url}`,
        {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers: HEADERS
        })
        .then(handleResponse)
        .catch(handleError)
}

export const get = async (url, queryString = '?') => {
    return fetch(`${API_URL}${url}` + queryString,
        {
            method: 'GET',
            headers: HEADERS
        })
        .then(handleResponse)
        .catch(handleError)
}

export const handleResponse = async response => {
    const data = await response.json();
    if (response.ok) return data;
    if (response.status === 401) {
        localStorage.clear()
        throw new Error('Tekrar login ol.')
    }
    throw new Error(data?.msg || 'An error occurred.');
}

export const handleError = err => {
    throw err;
}
